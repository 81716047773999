<template>
  <div class="puroshkar-lists" style="border-radius: 200px;">
    <div class="puroshkar-slider-large" style="border-radius: 2px;">
      <div
        v-for="(tournament, index) in tournaments"
        :key="tournament.tournamentId"
        style="width: 100%"
      >
        <img
          :src="tournament.thumbnail_path"
          style="text-align:center; width: 100%;border-radius: 2px;"
        />
        <div
          class="text-sections"
          :class="inner"
          style="background-color: white;"
        >
          <h2 style="text-align:center;">{{ tournament.title }}</h2>
          <h3 :class="runningTournament">
            {{ game_timer(tournament, $root.translation) }}
          </h3>
          <h4>
            <button
              v-if="
                tournament.isEnrolled === 'no' &&
                  state === 'Home' &&
                  tournament.endDateOfTournamentInSec > lastRegistrationTime
              "
              class="comn-btn"
              @click="
                showModal(
                  'regModal',
                  tournament.package_id,
                  tournament.tournamentId,
                  index
                )
              "
            >
              {{ $root.translation.enroll_now }}
            </button>
            <button
              v-else-if="
                tournament.isEnrolled === 'no' &&
                  state === 'Home' &&
                  tournament.endDateOfTournamentInSec < lastRegistrationTime
              "
              class="comn-btn not-active"
              disabled="disabled"
            >
              {{ $root.translation.enroll_now }}
            </button>
            <button
              v-else-if="
                tournament.isEnrolled === 'yes' &&
                  ['notStarted', 'finished'].includes(
                    tournament.tournamentStatus
                  )
              "
              class="comn-btn not-active"
              disabled="disabled"
            >
              {{ $root.translation.play }}
            </button>
            <router-link
              v-else-if="
                tournament.isEnrolled === 'yes' &&
                  tournament.tournamentStatus === 'running'
              "
              class="comn-btn"
              tag="button"
              :to="{
                name: 'GamePlay',
                params: { gameId: tournament.tournamentId }
              }"
            >
              {{ $root.translation.play }}
            </router-link>
          </h4>
          <h5
            v-if="
              tournament.isEnrolled === 'yes' &&
                ['running', 'finished'].includes(tournament.tournamentStatus)
            "
          >
            <router-link
              :to="{
                name: 'Leaderboard',
                params: { tournamentId: tournament.tournamentId },
                query: { title: encodeURIComponent(tournament.title) }
              }"
              tag="button"
              class="comn-btn-ledrbd"
            >
              {{ $root.translation.leaderboard }}
            </router-link>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TournamentList",
  props: [
    "tournaments",
    "inner",
    "runningTournament",
    "state",
    "lastRegistrationTime"
  ],
  methods: {
    time_convert(totalSeconds = 0) {
      let hours,
        minutes,
        seconds = "00";
      if (totalSeconds > 0) {
        hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        minutes = Math.floor(totalSeconds / 60);
        seconds = totalSeconds % 60;
        minutes = String(minutes).padStart(2, "0");
        hours = String(hours).padStart(2, "0");
        seconds = String(seconds).padStart(2, "0");
        return `${hours}:${minutes}:${seconds}`;
      } else {
        return `00:00:00`;
      }
    },
    timeFormat(totalSeconds = 0) {
      return this.time_convert(totalSeconds);
    },
    timer() {},
    showModal(id, packageId, tournamentId, index) {
      this.$emit("enroll", {
        packageId: packageId,
        tournamentId: tournamentId,
        id: id,
        index: index
      });
    },
    playGame(tournamentId) {
      this.$emit("play", { tournamentId: tournamentId });
    },
    game_timer(tournament, translation) {
      let response;
      let {
        tournamentStatus,
        startDateOfTournamentInSec,
        endDateOfTournamentInSec,
        tournamentEndInSecAgo
      } = tournament;
      let { startIn, endIn, end, ago } = translation;
      if (tournamentStatus === "notStarted") {
        let timer = this.timeFormat(startDateOfTournamentInSec);
        response = `${startIn} ${timer}`;
      } else if (tournamentStatus === "running") {
        let timer = this.timeFormat(endDateOfTournamentInSec);
        response = `${endIn} ${timer}`;
      } else if (tournamentStatus === "finished") {
        let timer = this.timeFormat(tournamentEndInSecAgo);
        response = `${end} ${timer} ${ago}`;
      }
      return response;
    }
  }
};
</script>

<style scoped></style>
