<template>
  <div class="modal-background" id="regModal">
    <div class="modal-content">
      <span class="close" @click="hideModal('regModal')"></span>
      <form id="subscriotion-frm" @submit="registration" method="post">
        <h5>মোবাইল নম্বর</h5>
        <div class="form-group">
          <label v-if="errorMessage">{{ errorMessage }}</label>
          <label> নিচের বক্সে আপনার মোবাইল নম্বরটি লিখুন</label>
          <input
            class="form-control"
            id="msisdn-control"
            type="number"
            v-model="msisdn"
            name="msisdn"
            maxlength="14"
            minlength="11"
            placeholder="01XXXXXXXXX"
            required
          />
        </div>

        <div class="subscription-submit-btn-home" v-if="button">
          <button
            class="send-coin"
            id="next-step"
            type="submit"
            :disabled="disabled"
          >
            Next
          </button>
        </div>
        <loader
          v-else
          inner-class="{color: red;'text-align': center;padding: 25px}"
        />
      </form>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import popup from "@/js/popup";

export default {
  name: "GameRegistration",
  components: {
    loader: Loader
  },
  data: () => {
    return {
      msisdn: ""
    };
  },
  props: ["disabled", "button", "errorMessage"],
  methods: {
    hideModal: function(id) {
      popup.hideModal(id);
    },
    registration(e) {
      e.preventDefault();
      return this.$emit("registration", { msisdn: this.msisdn });
    }
  },
  watch: {
    msisdn(val) {
      let msisdn = val ? popup.msisdnLength(val, 11, 14) : true;
      let isDisable = msisdn;
      this.$emit("buttonStatus", { isDisable: isDisable });
    }
  }
};
</script>

<style scoped></style>
