<template>
  <h3 :class="textShadow">{{ title }}</h3>
</template>

<script>
export default {
  name: "Title",
  props: ["title", "textShadow"]
};
</script>

<style scoped></style>
