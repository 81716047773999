<template>
  <div class="modal-background" id="otpModal">
    <div class="modal-content otp">
      <span class="close" @click="hideModal('otpModal')"></span>
      <form id="subscriotion-frm-2" @submit="otpVerification" method="post">
        <!--        <h5>ওটিপি</h5>-->
        <div class="screen-texts">
          <h5>ওটিপি</h5>
          <br />
          <div class="bold-text">
            {{ otpAttemptRemaining }} বার /বাকি আছে| {{ errorMessage }}
          </div>
        </div>
        <div class="form-group">
          <input
            class="form-control"
            type="text"
            v-model="name"
            name="name"
            maxlength="40"
            minlength="2"
            placeholder="আপনার নাম"
            required
            v-if="!isUserSubscribed"
          />
          <br />
          <!--          <label> চার ডিজিটের ওটিপি কোডটি নিচের বক্সে লিখুন</label>-->
          <input
            class="form-control"
            id="otp-control"
            type="number"
            v-model="otp"
            name="otp"
            maxlength="11"
            placeholder="চার ডিজিটের ওটিপি কোডটি বক্সে লিখুন"
          />
        </div>

        <div class="resend-otp" v-if="button">
          <button class="rsnd-1" type="button" @click="resendCode">
            ওটিপি কোডটি রিসেন্ড করুন
          </button>
        </div>
        <loader
          v-else
          inner-class="{color: red;'text-align': center;padding: 25px}"
        />
        <div class="subscription-submit-btn-home">
          <!-- <button
            class="send-coin"
            type="submit"
            :disabled="disabled"
            v-if="button"
          >
            Next
          </button> -->
          <button class="send-coin" type="submit" v-if="button">
            Next
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import popup from "@/js/popup";

export default {
  name: "Otp",
  components: {
    loader: Loader
  },
  data: () => {
    return {
      otp: "",
      name: ""
    };
  },
  props: [
    "button",
    "disabled",
    "errorMessage",
    "otpAttemptRemaining",
    "isUserSubscribed",
    "username"
  ],
  methods: {
    hideModal(id) {
      popup.hideModal(id);
    },
    resendCode() {
      return this.$emit("resendCode");
    },
    otpVerification(e) {
      e.preventDefault();
      return this.$emit("otpVerification", { otp: this.otp, name: this.name });
    }
  },
  watch: {
    otp(val) {
      let name;
      let otp = val ? popup.msisdnLength(val, 4, 4) : true;
      if (!this.$props.isUserSubscribed) {
        name = this.name ? popup.msisdnLength(this.name, 2, 40) : true;
      } else {
        name = false;
      }
      let isDisable = !(!otp && !name);
      this.$emit("buttonStatus", { isDisable: isDisable });
    },
    name(val) {
      let otp = this.otp ? popup.msisdnLength(this.otp, 4, 4) : true;
      let name = val ? popup.msisdnLength(val, 2, 40) : true;
      let isDisable = !(!otp && !name);
      this.$emit("buttonStatus", { isDisable: isDisable });
    }
  }
};
</script>

<style scoped></style>
