<template>
  <div :class="innerClass"></div>
</template>

<script>
export default {
  name: "Separaror",
  props: ["innerClass"]
};
</script>

<style scoped></style>
