var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"puroshkar-lists",staticStyle:{"border-radius":"200px"}},[_c('div',{staticClass:"puroshkar-slider-large",staticStyle:{"border-radius":"2px"}},_vm._l((_vm.tournaments),function(tournament,index){return _c('div',{key:tournament.tournamentId,staticStyle:{"width":"100%"}},[_c('img',{staticStyle:{"text-align":"center","width":"100%","border-radius":"2px"},attrs:{"src":tournament.thumbnail_path}}),_c('div',{staticClass:"text-sections",class:_vm.inner,staticStyle:{"background-color":"white"}},[_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(tournament.title))]),_c('h3',{class:_vm.runningTournament},[_vm._v(" "+_vm._s(_vm.game_timer(tournament, _vm.$root.translation))+" ")]),_c('h4',[(
              tournament.isEnrolled === 'no' &&
                _vm.state === 'Home' &&
                tournament.endDateOfTournamentInSec > _vm.lastRegistrationTime
            )?_c('button',{staticClass:"comn-btn",on:{"click":function($event){return _vm.showModal(
                'regModal',
                tournament.package_id,
                tournament.tournamentId,
                index
              )}}},[_vm._v(" "+_vm._s(_vm.$root.translation.enroll_now)+" ")]):(
              tournament.isEnrolled === 'no' &&
                _vm.state === 'Home' &&
                tournament.endDateOfTournamentInSec < _vm.lastRegistrationTime
            )?_c('button',{staticClass:"comn-btn not-active",attrs:{"disabled":"disabled"}},[_vm._v(" "+_vm._s(_vm.$root.translation.enroll_now)+" ")]):(
              tournament.isEnrolled === 'yes' &&
                ['notStarted', 'finished'].includes(
                  tournament.tournamentStatus
                )
            )?_c('button',{staticClass:"comn-btn not-active",attrs:{"disabled":"disabled"}},[_vm._v(" "+_vm._s(_vm.$root.translation.play)+" ")]):(
              tournament.isEnrolled === 'yes' &&
                tournament.tournamentStatus === 'running'
            )?_c('router-link',{staticClass:"comn-btn",attrs:{"tag":"button","to":{
              name: 'GamePlay',
              params: { gameId: tournament.tournamentId }
            }}},[_vm._v(" "+_vm._s(_vm.$root.translation.play)+" ")]):_vm._e()],1),(
            tournament.isEnrolled === 'yes' &&
              ['running', 'finished'].includes(tournament.tournamentStatus)
          )?_c('h5',[_c('router-link',{staticClass:"comn-btn-ledrbd",attrs:{"to":{
              name: 'Leaderboard',
              params: { tournamentId: tournament.tournamentId },
              query: { title: encodeURIComponent(tournament.title) }
            },"tag":"button"}},[_vm._v(" "+_vm._s(_vm.$root.translation.leaderboard)+" ")])],1):_vm._e()])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }